import { customAPIHandler } from "(apis)/api-interface/api-handler";
import { useState } from "react";
import { OrderAPI } from "../../../(apis)/(shared-apis)/order-apis";
import {
  ButtonFill,
  ButtonOutlined,
} from "../../../shared-components/ui/CustomButtons";
import toast from "react-hot-toast";

export default function DeleteOrderModal(props: any) {
  const [isSubmittingRequest, setIsSubmittingRequest] = useState(false);
  const Delete = async () => {
    setIsSubmittingRequest(true);
    try {
      const response = await customAPIHandler(
        "DeleteOrder",
        props.orderId,
        null,
        props.entity
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        window.location.href = "/oms";
      } else {
        toast.error("Failed To Delete Order");
      }
    } catch (err) {
      toast.error("Failed To Delete Order");
    } finally {
      setIsSubmittingRequest(false);
    }
  };

  return (
    <div
      id="createProductModal"
      tabIndex={-1}
      aria-hidden="true"
      className={`${
        props.active ? "" : "hidden"
      } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  flex justify-center items-center w-full h-screen backdrop-blur-sm !space-x-0`}
    >
      <div className="relative w-full max-w-2xl max-h-full p-4">
        <div className="relative p-4 bg-white rounded-lg shadow ">
          <div className="ml-2 text-gray-900 font-bold text-xl">
            Are you sure you want to
            <span className="text-red-500"> delete</span> this order?
          </div>
          <div className="flex items-center justify-start md:justify-end gap-2 mt-4 flex-wrap">
            <ButtonOutlined
              disabled={isSubmittingRequest}
              handleClick={() => {
                if (isSubmittingRequest) return;
                props.onCancel();
              }}
            >
              Cancel
            </ButtonOutlined>
            <ButtonFill
              disabled={isSubmittingRequest}
              handleClick={() => {
                if (isSubmittingRequest) return;
                Delete();
              }}
            >
              {isSubmittingRequest ? "Deleting.." : "Delete Order"}
            </ButtonFill>
          </div>
        </div>
      </div>
    </div>
  );
}
