import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Table, InputNumber, message, Select, Divider } from "antd";
import { useEffect, useState } from "react";
import CustomFormField from "shared-components/form-fields/custom-form-field";
import CustomAntdModal from "shared-components/modal/CustomAntdModal";
import { categoryValues } from "constants/constantvalues";
import { FormikProps } from "formik";



// Define the type for the props passed to the component
interface PaymentModalProps {
  openModal: boolean;
  setOpenModal: (isOpen: boolean) => void;
  isEditMode?: boolean;
  isEmployee?: boolean;
  formik?: FormikProps<any>; // You can replace `any` with the specific form values interface
  employeeData?: any[];
  serviceProviderData?: any[];
  bankAccountsData?: any[];
  selectedemployeeData?: any;
}

const PaymentModal: React.FC<PaymentModalProps> =({
  openModal,
  isEditMode,
  setOpenModal,
  formik,
  employeeData,
  serviceProviderData,
  bankAccountsData,
  isEmployee,
  selectedemployeeData,
}) => {
  const [selectedCategory, setSelectedCategory] = useState<string>(formik?.values?.categoryAmountList);
  const [amount, setAmount] = useState<number>(null);
  useEffect(() => {
    const total = formik?.values?.categoryAmountList?.reduce(
      (sum, item) => sum + item?.amount,
      0
    );
    formik.setFieldValue("amount", total);
  }, [formik?.values?.categoryAmountList]);
  console.log("selectedemployeeData", selectedemployeeData)
  useEffect(() => {
    
    if (isEmployee) {
      formik.setFieldValue('type', 'Internal');
      formik?.setFieldValue("employee", selectedemployeeData?.id);
    }
   
  }, [isEmployee, selectedemployeeData, openModal]);
  // Handle adding category-amount pairs
  const handleAddCategoryAmount = () => {
    if (!selectedCategory || amount <= 0) {
      message.error("Please select a valid category and amount.");
      return;
    }

    const newCategoryAmount = {
      category: selectedCategory,
      amount: amount,
    };
console.log("newCategoryAmount",newCategoryAmount)
    // Update Formik values
    if(formik?.values?.categoryAmountList?.length>0) {
      formik?.setFieldValue("categoryAmountList", [
        ...formik?.values?.categoryAmountList,
        newCategoryAmount,
      ]);

    }else{
      formik?.setFieldValue("categoryAmountList", [newCategoryAmount]);
    }

    setSelectedCategory(""); // Clear after adding
    setAmount(0); // Reset amount
  };

  // Handle deleting a category-amount pair
  const handleDeleteCategory = (record) => {
    const updatedList = formik?.values?.categoryAmountList?.filter(
      (item) => item.category !== record.category
    );

    // Update Formik values
    formik.setFieldValue("categoryAmountList", updatedList);
  };

  console.log("formik?.values?.categoryAmountList", formik?.values?.categoryAmountList)
  // Columns for AntD table
  const columns = [
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Amount (PKR)",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Button
          icon={<DeleteOutlined />}
          onClick={() => handleDeleteCategory(record)}
        />
      ),
    },
  ];

  return (
    <CustomAntdModal
      isOpenModal={openModal}
      title={isEditMode ? `Edit Payment` : `Add Payment`}
      setIsOpenModal={setOpenModal}
      formik={formik}
    >
      <form onSubmit={formik.handleSubmit}>
        {/* Type Selector (Internal/External) */}
        <CustomFormField
          type="select"
          name="type"
          label="Payment Type"
          disabled={isEditMode || isEmployee}
          formik={formik}
          options={[
            { label: "Internal", value: "Internal" },
            { label: "External", value: "External" },
          ]}
          require={true}
        />
           {formik.values.type === "External" && (
          <>
            <CustomFormField
              type="select"
              name="serviceProvider"
              label="Service Provider"
              formik={formik}
              disabled={isEditMode}
              options={serviceProviderData}
              require={true}
              isSetValue={true}
            />
          

            <CustomFormField
              type="text"
              name="bankName"
              label="Bank Name"
              formik={formik}
              require={true}
            />
          </>
        )}

        {/* Internal case fields */}
        {formik.values.type === "Internal" && (
          <>
            <CustomFormField
              type="select"
              name="employee"
              label="Employee"
              formik={formik}
              disabled={isEditMode || isEmployee}
              isSetValue={true}
              options={isEmployee?[{
                label: selectedemployeeData?.name,
                value: selectedemployeeData?.id,
              }]:employeeData}
              require={true}
              
            />
            <CustomFormField
              type="select"
              name="bankName"
              label="Employee Bank"
              isSetValue={true}
              formik={formik}
              options={isEmployee? selectedemployeeData?.bankDetails?.map((bank)=>{
                return {label:`(${bank.bankName})-(${bank.accountHolderName})-(${bank.accountNumber})` , value: bank.bankName };
              }): employeeData
                ?.filter(
                  (employee) => employee._id === formik.values.employee
                )?.[0]
                ?.bankDetails?.map((bank) => {
                  return { label:`(${bank.bankName})-(${bank.accountHolderName})-(${bank.accountNumber})` , value: bank.bankName };
                })}
              require={true}
            />
          </>
        )}
        <Divider>Category and Amount</Divider>
        {/* Categories and Amount Fields */}
        <div className="shadow-md my-10 p-5">
          <div className="flex justify-between gap-4 items-end">
            <div>
              <label className="!mb-4">Select Category</label>
              <Select
                options={categoryValues || []}
                className="w-96"
                placeholder="Select"
                value={selectedCategory as string}
                onChange={(value) => setSelectedCategory(value)}
              />
            </div>
            <div>
              <label className="!mb-4">Amount</label>
              <InputNumber
                min={0}
                // defaultValue={''}
                className="w-96"
                value={amount}
                onChange={(value) => setAmount(value)}
                placeholder="Enter amount"
              />
            </div>
            <Button
            className="!bg-[#000000]"
              icon={<PlusOutlined />}
              onClick={handleAddCategoryAmount}
              type="primary"
            >
              Add
            </Button>
          </div>

          {/* Table of added categories and amounts */}
        { formik?.values?.categoryAmountList?.length >0 &&  <Table
            columns={columns}
            dataSource={formik.values.categoryAmountList}
            rowKey="category"
            pagination={false}
            style={{ marginTop: 16 }}
          />}
        </div>
        {/* Common Fields */}
        <CustomFormField
          type="select"
          name="accountNumber"
          label="Select Account Number"
          formik={formik}
          options={bankAccountsData}
          require={true}
          isSetValue={true}
        />
<div className="flex gap-4">
<div className="w-full md:w-1/2">
        <CustomFormField
          type="number"
          name="amount"
          label="Total Amount"
          formik={formik}
          require={true}
          prefix="PKR"
          disabled={true}
        />

</div>
<div className="w-full md:w-1/2">
        <CustomFormField
          type="date"
          name="createdDate"
          label="Created Date"
          formik={formik}
          require={true}
        />

</div>
</div>

        <CustomFormField
          type="image"
          name="screenshot"
          label="Upload Screenshot"
          formik={formik}
          isMultipleImage={false}
          require={false} // Optional field
        />
      </form>
    </CustomAntdModal>
  );
};

export default PaymentModal;
