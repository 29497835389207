// BankAccountsTable.tsx

import { apiRequest } from "(apis)/api-interface/api-handler";
import { PlusOutlined } from "@ant-design/icons";
import { message } from "antd";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import CustomButton from "shared-components/form-fields/custom-button";
import CustomFormField from "shared-components/form-fields/custom-form-field";
import CustomAntdModal from "shared-components/modal/CustomAntdModal";
import GlobalTable from "shared-components/table/GlobalTable";
import { EditIcon } from "shared-components/ui/Icons";
import { UserContext } from "state-management/user-context";
import * as Yup from "yup";
import dayjs from "dayjs"
interface BankAccount {
  _id: string;
  accountNumber: string;
  holderName: string;
  bankName: string;
}

const BankAccounts: React.FC = () => {
  const [bankAccounts, setBankAccounts] = useState<BankAccount[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [addPopup, setAddPopup] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const { user } = useContext(UserContext);
  useEffect(() => {
    fetchBankAccounts();
  }, []);

  const fetchBankAccounts = async () => {
    setLoading(true);
    try {
      const response = await apiRequest("getAllAccounts"); // Your API endpoint
      if(response?.data?.isSuccess){
        setBankAccounts(response.data.accounts);
      }
    } catch (error) {
      message.error(error?.response?.data?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Account Number",
      dataIndex: "accountNumber",
      key: "accountNumber",
    },
    {
      title: "Holder Name",
      dataIndex: "holderName",
      key: "holderName",
    },
    {
      title: "Bank Name",
      dataIndex: "bankName",
      key: "bankName",
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) =>dayjs(createdAt).format('DD/MM/YYYY'),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="flex gap-4">
          <div
            className="text-xl flex gap-4"
            onClick={() => handleEdit(record)}
          >
            <EditIcon color="text-gray-800" size="w-6 h-6" />
          </div>
        </div>
      ),
    },
  ];
  const validationSchema = Yup.object().shape({
    accountNumber: Yup.string().required("Account Name is required"),
    holderName: Yup.string().required("Account Holder Name is required"),
    bankName: Yup.string().required("Bank Name is required"),
  });
  const formik = useFormik({
    initialValues: {
      accountNumber: "",
      editId: "",
      holderName: "",
      bankName: "",
      createdAt: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      // Add your submission logic here
      const payload = {
        ...values,
        addedBy: user?.fullname,
      };
      try {
        const { editId } = values;
        let res;
        if (isEditMode) {
          res = await apiRequest("updateAccount", payload, [editId]);
        } else {
          res = await apiRequest("addAccount", payload);
        }
        if (res.data?.isSuccess) {
          fetchBankAccounts();
          message.success(res?.data?.message);
          setAddPopup(false);
          resetForm();
        }
      } catch (error) {
        message.error(error?.response?.data?.message || error?.message);
      }
    },
  });
  const { handleSubmit, setValues } = formik;
  const handleEdit = (record) => {
    const {_id: id } = record;
    setValues({
      ...record,
      editId: id,
    });
    setIsEditMode(true);
    setAddPopup(true);
  };
  return (
    <div>
      <div className={`flex justify-end items-end  gap-4`}>
        <CustomButton
          text="Add New Account"
          onClick={() => {
            // setOpen(true)
            setAddPopup(true);
            setIsEditMode(false);
            formik.resetForm();
          }}
          icon={<PlusOutlined />}
        />
      </div>
      <GlobalTable data={bankAccounts} columns={columns} loading={loading} />

      <CustomAntdModal
        isOpenModal={addPopup}
        title={isEditMode ? `Edit Account` : `Add Account `}
        setIsOpenModal={setAddPopup}
        formik={formik}
        width={800}
      >
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col  gap-2">
            <div className="w-full ">
              <CustomFormField
                type="number"
                name="accountNumber"
                 prefix="PKR"
                formik={formik}
                label="Account Number"
              />
            </div>
            <div className="w-full ">
              <CustomFormField
                type="text"
                name="holderName"
                formik={formik}
                label="Account Holder Name"
              />
            </div>
            <div className="w-full ">
              <CustomFormField
                type="text"
                name="bankName"
                formik={formik}
                label="Bank Name"
              />
            </div>
            <div className="w-full ">
              <CustomFormField
                type="date"
                name="createdAt"
                formik={formik}
                label="created Date"
              />
            </div>
          </div>
       
    
        </form>
      </CustomAntdModal>
    </div>
  );
};

export default BankAccounts;
