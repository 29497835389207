export const paymentsInitialValues = {
    type: "", // Internal or External
    serviceProvider: null, // External case only
    employee: null, // Internal case only
    amount: "", // Common field
    accountNumber: "", // Internal case only
    bankName: "", // Internal case only
    screenshot: null, // Optional in both cases,
    editPaymentId: "",
    categories:[],
    categoryAmountList:[],
    createdDate:"",
  };