import { apiRequest } from "(apis)/api-interface/api-handler";
import { PlusOutlined } from "@ant-design/icons";
import { message } from "antd";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import CustomButton from "shared-components/form-fields/custom-button";
import CustomFormField from "shared-components/form-fields/custom-form-field";
import CustomAntdModal from "shared-components/modal/CustomAntdModal";
import GlobalTable from "shared-components/table/GlobalTable";
import { EditIcon } from "shared-components/ui/Icons";
import { UserContext } from "state-management/user-context";
import * as Yup from "yup";
import dayjs from "dayjs";

interface MiscellaneousPayment {
  _id: string;
  name: string;
  amount: number;
  description?: string;
  createdAt: string;
}

const MiscellaneousPayments: React.FC = () => {
  const [payments, setPayments] = useState<MiscellaneousPayment[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [addPopup, setAddPopup] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const { user } = useContext(UserContext);

  useEffect(() => {
    fetchPayments();
  }, []);

  const fetchPayments = async () => {
    setLoading(true);
    try {
      const response = await apiRequest("getAllMiscellaneousPayments"); // Your API endpoint
      if (response?.data?.isSuccess) {
        setPayments(response.data.payments);
      }
    } catch (error) {
      message.error(error?.response?.data?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Expense Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: " Expense Amount (PKR)",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt: string) => dayjs(createdAt).format('DD/MM/YYYY'),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="flex gap-4">
          <div
            className="text-xl flex gap-4"
            onClick={() => handleEdit(record)}
          >
            <EditIcon color="text-gray-800" size="w-6 h-6" />
          </div>
        </div>
      ),
    },
  ];

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Payment Name is required"),
    amount: Yup.number().required("Amount is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      amount: '',
      description: "",
      editId: "",
      createdAt: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const payload = {
        ...values,
        addedBy: user?.fullname,
        createdAt: values.createdAt ||new Date(),
      };
      try {
        const { editId } = values;
        let res;
        if (isEditMode) {
          res = await apiRequest("updateMiscellaneousPayment", payload, [editId]);
        } else {
          res = await apiRequest("addMiscellaneousPayment", payload);
        }
        if (res.data?.isSuccess) {
          fetchPayments();
          message.success(res?.data?.message);
          setAddPopup(false);
          resetForm();
        }
      } catch (error) {
        message.error(error?.response?.data?.message || error?.message);
      }
    },
  });

  const { handleSubmit, setValues } = formik;

  const handleEdit = (record) => {
    const { _id: id } = record;
    setValues({
      ...record,
      editId: id,
    });
    setIsEditMode(true);
    setAddPopup(true);
  };

  return (
    <div>
      <div className={`flex justify-end items-end  gap-4`}>
        <CustomButton
          text="Add New Miscellaneous Payment"
          onClick={() => {
            setAddPopup(true);
            setIsEditMode(false);
            formik.resetForm();
          }}
          icon={<PlusOutlined />}
        />
      </div>
      <GlobalTable data={payments} columns={columns} loading={loading} />

      <CustomAntdModal
        isOpenModal={addPopup}
        title={isEditMode ? `Edit Miscellaneous  Payment` : `Add Miscellaneous Payment`}
        setIsOpenModal={setAddPopup}
        formik={formik}
        width={800}
      >
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col gap-2">
            <div className="w-full">
              <CustomFormField
                type="text"
                name="name"
                formik={formik}
                label=" Name of Expense"
              />
            </div>
            <div className="w-full">
              <CustomFormField
                type="number"
                name="amount"
                prefix="PKR"
                formik={formik}
                label="Amount of Expense"
              />
            </div>
            <div className="w-full">
              <CustomFormField
                type="date"
                name="createdAt"
                formik={formik}
                label="Created Date"
              />
            </div>
            <div className="w-full">
              <CustomFormField
                type="textarea"
                name="description"
                formik={formik}
                label="Description"
              />
            </div>
      
          </div>
        </form>
      </CustomAntdModal>
    </div>
  );
};

export default MiscellaneousPayments;
