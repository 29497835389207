import { useFormik } from "formik";

import PaymentModal from "./modal";
import PaymentTable from "./table";
import CustomButton from "shared-components/form-fields/custom-button";
import { PlusOutlined } from "@ant-design/icons";
import { useContext, useEffect, useState } from "react";
import { apiRequest } from "(apis)/api-interface/api-handler";
import { message } from "antd";
import { UserContext } from "state-management/user-context";
import identifyAndConvertNewImagesToBase64 from "utility/form/identifyAndConvertNewImagesToBase64";
import { generateAntdImageObjectFromUrl } from "utility/form/generate-antd-image-object-from-url";
import MiscellaneousPayments from "../miscellaneous-payments";
import { paymentValidationSchema } from "./schema";
import { paymentsInitialValues } from "./initialValues";

const ManagePayments = () => {
  const [openModal, setOpenModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentData, setPaymentData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [bankAccountsData, setBankAccountsData] = useState([]);
  const [serviceProviderData, setServiceProviderData] = useState([]);
  // Initial form values

  const { user } = useContext(UserContext);
  // Validation schema


  const formik = useFormik({
    initialValues:paymentsInitialValues,
    validationSchema:paymentValidationSchema,
    onSubmit: async(values, {resetForm}) => {
      const screensShotBase64 = values?.screenshot &&  await identifyAndConvertNewImagesToBase64(values?.screenshot);
      console.log("values", values)
      const payload = {
        ...values,
        paymentBreakdown:values?.categoryAmountList,
        addedBy: user?.fullname,
        screenshot: screensShotBase64?.[0],
        createdDate: values?.createdDate || new Date(),
      };
      try {
        const { editPaymentId } = values;
        let res;
        if (isEditMode) {
          res = await apiRequest("updatePayment", payload, [editPaymentId]);
        } else {
          res = await apiRequest("addPayment", payload);
        }
        console.log("res: " + res);
        if (res.data?.isSuccess) {
          fetchPayments();
          message.success(res?.data?.message);
          setOpenModal(false);
          resetForm();
        }
      } catch (error) {
        message.error(error?.response?.data?.message || error?.message);
      }
    },
  });

  const fetchPayments = async () => {
    try {
      setIsLoading(true);
      const res = await apiRequest("getAllPaymentsServiceProviderAndEmployee");
      if (res.data?.isSuccess) {
        if(res.data?.payments?.length > 0) {
          const updatedRow = res.data?.payments?.map((item) => {
            return {
             ...item,
              label: item.name,
              value: item._id,
            };
          });
          setPaymentData(updatedRow);
        }else{
          setPaymentData([]);
        }
       if(res.data?.serviceProviders?.length > 0){
        const updatedRow = res.data?.serviceProviders?.map((item) => {
          return {
             ...item,
              label: item.name,
              value: item._id,
            };
          });
          setServiceProviderData(updatedRow);
        }else{
          setServiceProviderData([]);
        }
        if(res.data?.employees?.length > 0){
          const updatedRow = res.data?.employees?.map((item:any) => {
            return {
             ...item,
              label: item.name,
              value: item._id,
            };
          });
          setEmployeeData(updatedRow);
          }else{
            setEmployeeData([]);
          }
        if(res.data?.bankAccounts?.length > 0){
          const updatedRow = res.data?.bankAccounts?.map((item:any) => {
            return {
             ...item,
              label:`(${item.bankName})-(${item.holderName})-(${item.accountNumber})` ,
              value: item.accountNumber,
            };
          });
          setBankAccountsData(updatedRow);
          }else{
            setBankAccountsData([]);
          }
      }
    } catch (error) {
      message.error(error?.response?.data?.message || error?.message);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchPayments();
  }, []);
  const handleEdit = (record) => {
    const { _id , paymentBreakdown} = record;
    console.log("record",record)
    const img = generateAntdImageObjectFromUrl(record?.screenshotUrl);
    formik.setValues({
      ...record,
      categoryAmountList:paymentBreakdown,
      editPaymentId: _id,
      screenshot : [img]
    });
    setIsEditMode(true);
    setOpenModal(true);
  };

  const handleDelete = async (recordId) => {
    try {
      const res = await apiRequest("deletePayment", null, [recordId]);
      if (res.data?.isSuccess) {
        message.success(res?.data?.message);
        fetchPayments();
      }
    } catch (error) {
      message.error(error?.response?.data?.message || error?.message);
    }
  };
  return (
    <div>
      <div className="">
        <div className={`flex justify-end items-end  gap-4`}>
          <CustomButton
            text="Add New Payment"
            onClick={() => {
              // setOpen(true)
              setOpenModal(true);
              setIsEditMode(false);
              formik.resetForm()
            }}
            icon={<PlusOutlined />}
          />
        </div>
        <div>
          <PaymentTable
            handleEdit={handleEdit}
            isLoading={isLoading}
            paymentData={paymentData}
            handleDelete={handleDelete}
          />
        </div>
      </div>
      <div className="">
        <div className="flex items-center justify-between pb-1 mt-5">
          <h2 className="text-2xl font-bold text-gray-700">Miscellaneous Payment</h2>
        </div>
        <MiscellaneousPayments />
      </div>
      <PaymentModal
        openModal={openModal}
        isEditMode={isEditMode}
        setOpenModal={setOpenModal}
        formik={formik}
        employeeData={employeeData}
        serviceProviderData={serviceProviderData}
        bankAccountsData={bankAccountsData}
      />
    </div>
  );
};

export default ManagePayments;
